<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <!--        <div class="vx-row">-->
                <!--          <h3 class="ml-2">Thông tin vật dụng</h3>-->
                <!--        </div>-->
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="branchModel" :clearable="false" :options="branchOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="branch">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                    </div>
                </div>
                <!-- Content Row -->
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Tên vật dụng"
                                  v-model="deviceInfo.type"
                                  v-validate="'required'" name="equipmentType"/>
                        <span class="text-danger text-sm">{{ errors.first('equipmentType') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Nhà cung cấp"
                                  v-model="deviceInfo.supplier"
                                  v-validate="''" name="supplier"/>
                        <span class="text-danger text-sm">{{ errors.first('supplier') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Hãng" v-model="deviceInfo.brand"
                                  v-validate="'required'" name="brand"/>
                        <span class="text-danger text-sm">{{ errors.first('brand') }}</span>
                    </div>
                </div>
                <div class="vx-row">

                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Mã" v-model="deviceInfo.model"
                                  v-validate="'required'" name="model"/>
                        <span class="text-danger text-sm">{{ errors.first('model') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Ngày nhập</label>
                        <datepicker :format="'dd/MM/yyyy'" v-model="deviceInfo.importDate"
                                    data-vv-validate-on="input" v-validate="" name="importDate"></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('importDate') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Số lượng" :disabled="isUpdate || deviceInfo.deviceType == 0"
                                  v-model="deviceInfo.totalQuantity"
                                  v-validate="'numeric|max: 10' && 'required'" name="totalQuantity"/>
                        <span class="text-danger text-sm">{{ errors.first('totalQuantity') }}</span>
                    </div>
                    <!-- <div class="vx-col md:w-1/3 w-full mt-2">
                      <vs-input class="w-full" label="Số Serial" v-model="deviceInfo.serialNumber"
                                name="serialNumber"/>
                      <span class="text-danger text-sm">{{ errors.first('serialNumber') }}</span>
                    </div> -->
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Ngày nhập</label>
                        <datepicker :format="'dd/MM/yyyy'" v-model="deviceInfo.importDate"
                                    :disabledDates="disabledFn"
                                    data-vv-validate-on="input" v-validate="" name="importDate"></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('importDate') }}</span>
                    </div>
                    <!-- <div class="vx-col md:w-1/3 w-full mt-2">
                      <label class="vs-input--label">Trạng thái</label>
                      <v-select class="" v-model="deviceStatusModel" :clearable="false" :options="deviceStatusOptions"
                                data-vv-validate-on="blur" v-validate="'required'" name="deviceStatus"/>
                      <span class="text-danger text-sm">{{ errors.first('deviceStatus') }}</span>
                    </div> -->
                    <!-- <div class="vx-col md:w-1/6 w-full mt-2">
                      <label class="vs-input--label">Loại tài sản</label>
                      <v-select class="" v-model="deviceTypesModel" :clearable="false" :options="deviceTypesOptions" :disabled="isUpdate"
                                data-vv-validate-on="blur" v-validate="'required'" name="deviceType"/>
                      <span class="text-danger text-sm">{{ errors.first('deviceType') }}</span>
                    </div> -->

                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-2/3 w-full mt-5">
                        <vs-textarea class="w-full" label="Thông tin bổ sung" v-model="deviceInfo.metadata"
                                     name="metadata"/>
                        <span class="text-danger text-sm">{{ errors.first('metadata') }}</span>
                    </div>
                </div>
                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                    `${isUpdate ?
                                        'Cập nhật' : 'Tạo mới'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="equipmentList">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import utils from "../../../components/utils";

export default {
    components: {
        vSelect,
        Datepicker
    },
    data() {
        return {
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            isUpdateFace: false,
            branchOptions: [],
            deviceInfo: {},
            disabledFn: {
                customPredictor(date) {
                    if (date.getTime() >= new Date().getTime()) {
                        return true; // returns true if disabled
                    }
                }
            },
            workShiftSetting: [],
            timeSpans: [],
            file: '',
            fileName: 'Thêm dữ liệu khuôn mặt.',
            uploadPercent: 0,
            isShowPercent: false,
            deviceStatusOptions: [
                {value: 0, label: 'Hoạt động'},
                {value: 1, label: 'Sửa chữa'},
                {value: 2, label: 'Hỏng'}
            ],
            deviceTypesOptions: [
                {value: 0, label: "Device"},
                {value: 1, label: "Equipment"}
            ]
        }
    },
    created() {
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });
        this.deviceInfo = {
            organizationBranchId: this.$route.query.branch_id,
            status: 0,
            deviceType: 1,
            totalQuantity: 1
        };
        let deviceId = this.$route.query.id;
        if (deviceId) {
            setTimeout(() => {
                this.$vs.loading();
                this.$crm.post('/device/find-by-id/' + deviceId).then((response) => {
                    this.$vs.loading.close();

                    this.deviceInfo = response.data;
                    this.isUpdate = true;
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }, 0)
        }
    },
    computed: {
        branchModel: {
            get() {
                return {
                    label: this.branchOptions.find(x => x.value == this.deviceInfo.organizationBranchId).label,
                    value: this.deviceInfo.organizationBranchId
                }
            },
            set(obj) {
                this.deviceInfo.organizationBranchId = obj.value;
            }
        },
        deviceStatusModel: {
            get() {
                return {
                    label: this.deviceStatusOptions.find(x => x.value == this.deviceInfo.status).label,
                    value: this.deviceInfo.status
                }
            },
            set(obj) {
                this.deviceInfo.status = obj.value;
            }
        },
        deviceTypesModel: {
            get() {
                return {
                    label: this.deviceTypesOptions.find(x => x.value == this.deviceInfo.deviceType).label,
                    value: this.deviceInfo.deviceType
                }
            },
            set(obj) {
                this.deviceInfo.deviceType = obj.value;
                if (this.deviceInfo.deviceType == 1) this.deviceInfo.totalQuantity = 1;
            }
        },
        validateForm() {
            return !this.errors.any()
        },
    },
    methods: {
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;
                let url = this.isUpdate ? `/device/update/${this.deviceInfo.id}` : `/device/create/${this.deviceInfo.organizationBranchId}`,
                    data = this.deviceInfo;

                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} thiết bị thành công`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$router.push(`/user/manage-equipment${this.deviceInfo.organizationBranchId ? `?branch_id=${this.deviceInfo.organizationBranchId}` : ''}`).catch(() => {
                    })
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        equipmentList() {
            this.$router.push(`/user/manage-equipment${this.deviceInfo.organizationBranchId ? `?branch_id=${this.deviceInfo.organizationBranchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>

</style>
